import React from 'react'
import Navbar from './sections/Navbar';
import HeroSection from './sections/HeroSection';
import ServicesOverview from './sections/ServicesOverview';
import ValueProposition from './sections/ValueProposition';
import MainFeatures from './sections/MainFeatures';
import TechnologySecurity from './sections/TechnologySecurity';
import PremiumBenefits from './sections/PremiumBenefits';
import MissionVision from './sections/MissionVision';
import AboutUs from './sections/AboutUs'
import FinalCTA from './sections/FinalCTA'
import FAQ from './sections/FAQ';
import Footer from './sections/Footer';
import ComparisonTable from './sections/ComparisonTable';
import MobileMockups from './sections/MobileMockups';

import mockup from './sections/img/mockup.png';
import mockupw from './sections/img/mockupw.png';


function Home() {
  return (
    <>
      <div className="bg-gradient-to-r from-[#004AAD] to-[#C038E7] animate-gradient opacity-100 text-[#EDE6E6]">
        <Navbar />
        <HeroSection />
        <MobileMockups />
        <ServicesOverview />
        <ValueProposition />
        <div className='hidden md:block'>
          <ComparisonTable />
        </div>
        <TechnologySecurity />
        <FAQ/>
        <Footer />
      </div>
    </>
  )
}

export default Home