import React, { useState } from 'react';
import mockup from './img/mockup.png'
import mockupw from './img/mockupw.png'

import './MobileMockup.css';

const MobileMockups = () => {
  

  return (
    <div className='mb-10'>
          <img
            src={mockup}
            alt="mockup"
            className="absolute w-auto z-10 mockup mockup1"
          />
          <img
            src={mockupw}
            alt="mockupw"
            className="absolute w-auto z-10 mockup mockup2 "
          />
        </div>
  );
};

export default MobileMockups;
