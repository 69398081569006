import React, { useState } from 'react';
import { FaEnvelope, FaPhone, FaTools } from 'react-icons/fa';
import emailjs from 'emailjs-com';
import './Contattaci.css'; // Assicurati di creare questo file CSS
import Footer from './sections/Footer';
import Navbar from './sections/Navbar';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isSending, setIsSending] = useState(false);
  const [sendError, setSendError] = useState(null);
  const [sendSuccess, setSendSuccess] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSending(true);
    setSendError(null);
    setSendSuccess(null);
  
    // Dati per l'email al provider
    const providerEmailData = {
      from_name: formData.name,
      from_email: formData.email,
      message: 'Grazie per averci contattato. Il tuo messaggio è stato ricevuto e sarà elaborato al più presto.',
    };
  
    // Invio dell'email al provider
    emailjs.send('service_a93zv2o', 'template_y5m85ip', providerEmailData, '762ecfELT29WQJQxR')
      .then((response) => {
        console.log('Email to provider sent successfully:', response);
  
        // Dati per l'email al contattato
        const contactEmailData = {
          to_name: formData.name,
          to_email: formData.email,
          message: formData.message,
        };
  
        // Invio dell'email al contattato
        return emailjs.send('service_a93zv2o', 'template_39uismi', contactEmailData, '762ecfELT29WQJQxR');
      })
      .then((response) => {
        console.log('Email to contact sent successfully:', response);
        setSendSuccess('Messaggio inviato con successo!');
        toast.success('Messaggio inviato con successo!');

      })
      .catch((error) => {
        console.error('Failed to send email:', error);
        setSendError('Errore nell\'invio del messaggio.');
        toast.error('Errore nell\'invio del messaggio.');
      })
      .finally(() => {
        setIsSending(false);
      });
  };
  
  

  return (
    <>
      <Navbar />
      <section
        className="bg-gradient-to-r from-[#004AAD] to-[#C038E7] animate-gradient opacity-100 text-[#EDE6E6]"
        id="contact"
      >
        <div className="container mx-auto px-4 py-16 sm:px-6 lg:px-8 lg:py-20">
          <div className="text-center mb-6">
            <p className="text-base font-semibold uppercase tracking-wide mt-4 mb-8 text-blue-600 dark:text-blue-200">
              Contatto
            </p>
            <h2 className="font-heading mb-4 font-bold tracking-tight text-gray-900 mb-8 dark:text-white text-3xl sm:text-5xl">
              Mettiti in Contatto con Noi
            </h2>
            <p className="mx-auto mt-4 max-w-3xl text-xl text-gray-600 dark:text-slate-400 mb-12">
              Siamo qui per rispondere a tutte le tue domande e offrirti assistenza riguardo i nostri servizi.
            </p>
          </div>
          <div className="flex items-stretch justify-center">
            <div className="grid md:grid-cols-2 gap-6">
              <div className="info h-full pr-6">
                <div className="contact-section mb-12 flex flex-col items-start">
                  <div className="mb-4">
                    <p className="text-lg font-medium leading-6 mt-16 text-gray-900 dark:text-white">
                      Hai bisogno di assistenza tecnica?
                    </p>
                    <p className="text-gray-600 dark:text-slate-400">
                      Contattaci per risolvere qualsiasi problema tecnico.
                    </p>
                  </div>
                  <div className="flex items-center">
                    <div className="icon h-10 w-10 items-center justify-center rounded text-gray-50">
                      <FaTools className="h-6 w-6" />
                    </div>
                    <div className="ml-4">
                      <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white mb-2">
                        Supporto Tecnico
                      </h3>
                      <p className="text-gray-600 dark:text-slate-400">
                        Telefono: +39 388 565 5449
                      </p>
                      <p className="text-gray-600 dark:text-slate-400">
                        Email: denis@lexiweb.it
                      </p>
                    </div>
                  </div>
                </div>
                <div className="contact-section flex flex-col items-start">
                  <div className="mb-4">
                    <p className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                      Hai bisogno di assistenza amministrativa?
                    </p>
                    <p className="text-gray-600 dark:text-slate-400">
                      Contattaci per questioni amministrative e di gestione.
                    </p>
                  </div>
                  <div className="flex items-center">
                    <div className="icon h-10 w-10 items-center justify-center rounded text-gray-50">
                      <FaEnvelope className="h-6 w-6" />
                    </div>
                    <div className="ml-4">
                      <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white mb-2">
                        Amministrazione
                      </h3>
                      <p className="text-gray-600 dark:text-slate-400">
                        Telefono: +39 327 935 7570
                      </p>
                      <p className="text-gray-600 dark:text-slate-400">
                        Email: gabriel@lexiweb.it
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card h-fit max-w-6xl p-5 mt-16 bg-glass md:p-12">
                <h2 className="text-2xl font-bold dark:text-white mb-4">
                  Compila il modulo per ricevere assistenza
                </h2>
                <form id="contactForm" onSubmit={handleSubmit}>
                  <div className="mb-6">
                    <div className="mb-4">
                      <label
                        htmlFor="name"
                        className="text-xs uppercase tracking-wider"
                      >
                        Nome
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Il tuo nome"
                        className="w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md text-black"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="email"
                        className="text-xs uppercase tracking-wider"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Il tuo indirizzo email"
                        className="w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md text-black"
                      />
                    </div>
                  </div>
                  <div className="mb-6">
                    <label
                      htmlFor="textarea"
                      className="text-xs uppercase tracking-wider"
                    >
                      Messaggio
                    </label>
                    <textarea
                      id="textarea"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      cols="30"
                      rows="5"
                      placeholder="Scrivi il tuo messaggio..."
                      className="w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md text-black"
                    ></textarea>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className={`w-full bg-gradient-to-r from-[#004AAD] to-[#C038E7] animate-gradient text-white px-6 py-3 font-xl rounded-md ${
                        isSending ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                      disabled={isSending}
                    >
                      {isSending ? 'Invio in corso...' : 'Invia Messaggio'}
                    </button>
                  </div>
                  {sendSuccess && (
                    <p className="text-green-500 mt-4"></p>
                  )}
                  {sendError && (
                    <p className="text-red-500 mt-4"></p>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer />
      </section>
    </>
  );  
};

export default ContactPage;
