import React from 'react';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import './HeroAdapt.css';

// Assicurati di avere le immagini delle diverse criptovalute nel tuo progetto
import bitcoin from './img/bitcoin.png';
import ethereum from './img/ethereum.png';
import binance from './img/binance.png';
import ripple from './img/ripple.png';
import dogecoin from './img/dogecoin.png';
import tether from './img/tether.png';

const HeroSection = () => {
  return (
    <div className="hero-section h-screen flex flex-col justify-center items-center relative overflow-hidden">
      <div className="absolute inset-0 "></div>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="relative bg-glass pt-5 p-12 md:p-16 lg:p-14 backdrop-blur-xs shadow-lg text-center z-20 bottom-20 md:bottom-24 lg:bottom-20 "
      >
        <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold mb-4">
          Unisci i tuoi portafogli fiat e criptovalute e paga con un'unica
          carta.
        </h1>
        <p className="text-2xl md:text-3xl lg:text-3xl mb-8">
          Semplice, sicura e accessibile a tutti.
        </p>
        <Link to="services" smooth={true} duration={500}>
          <div className="flex justify-center items-center">
            <motion.button
              className="bg-white text-purple-800 px-6 py-3 md:px-8 md:py-4 lg:px-10 lg:py-5 flex ml-7 rounded-lg shadow-lg font-semibold "
              animate={{ scale: [1, 1.1, 1] }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                repeatType: 'loop',
              }}
            >
              Prova ora l'App
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                fill="none"
                viewBox="0 0 21 25"
                className="ml-3 w-6 h-6"
              >
                <path
                  fill="#2A7AFF"
                  d="M19.4696 8.35288c-.1422.11027-2.6512 1.52406-2.6512 4.66772 0 3.6362 3.1927 4.9225 3.2882 4.9544-.0147.0784-.5072 1.7617-1.6833 3.4769-1.0487 1.5093-2.144 3.0162-3.8101 3.0162-1.6662 0-2.095-.9678-4.0184-.9678-1.87446 0-2.54093.9997-4.06498.9997-1.52406 0-2.58746-1.3966-3.81013-3.1118C1.30344 19.3741.15918 16.2451.15918 13.2754c0-4.76325 3.09711-7.28945 6.14521-7.28945 1.61962 0 2.9697 1.06341 3.98651 1.06341.9679 0 2.4772-1.12712 4.3198-1.12712.6983 0 3.2074.06371 4.8589 2.43064ZM13.736 3.90569c.762-.90414 1.3011-2.15867 1.3011-3.413191 0-.173967-.0147-.350385-.0466-.492499-1.2398.0465547-2.7149.825733-3.6043 1.85729-.6983.79388-1.3501 2.0484-1.3501 3.32008 0 .19112.0319.38224.0466.44349.0784.0147.2058.03185.3332.03185 1.1124 0 2.5115-.74487 3.3201-1.74702Z"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                fill="none"
                viewBox="0 0 21 25"
                className="ml-3 w-6 h-6 mt-1"
              >
                <path
                  fill="#2A7AFF"
                  d="M1.12778.5 11.6247 11 1.12881 21.5c-.186645-.0785-.345921-.2104-.457804-.3791-.111883-.1687-.171378-.3668-.171004-.5693V1.44836C.500081 1.24613.55964 1.0484.671265.87977.782889.711141.941648.579071 1.12778.5ZM12.3535 11.7288l2.3729 2.373L3.45229 20.63l8.90121-8.9012Zm3.2976-3.29659 2.8935 1.67609c.1562.0906.2859.2207.376.3772.0902.1565.1376.3339.1376.5145 0 .1806-.0474.358-.1376.5145-.0901.1565-.2198.2865-.376.3772l-2.8945 1.6761L13.0823 11l2.5688-2.56779ZM3.45229 1.37002 14.7275 7.89721l-2.374 2.37399-8.90121-8.90118Z"
                ></path>
              </svg>
            </motion.button>
          </div>
        </Link>
      </motion.div>
      {/* Monete Criptovalute posizionate */}

      <img
  src={ethereum}
  alt="Ethereum"
  className="absolute w-auto z-30 desktop-small ethereum-style"
/>
<img
  src={binance}
  alt="Binance"
  className="absolute w-auto z-30 desktop-small binance-style"
/>
<img
  src={dogecoin}
  alt="Dogecoin"
  className="absolute w-auto z-30 desktop-small dogecoin-style"
/>
<img
  src={tether}
  alt="Tether"
  className="absolute w-auto z-30 desktop-small tether-style"
/>
<img
  src={bitcoin}
  alt="Bitcoin"
  className="absolute w-auto z-30 lg:w-24 desktop-small bitcoin-style"
/>

    </div>
  );
};

export default HeroSection;
