import React from 'react';

const CookiePolicy = () => {
  return (
    <div>
      <h1 className="text-3xl font-bold mb-4">Politica sui Cookie</h1>
      <p className="text-gray-700 mb-4">Ultimo aggiornamento: [Data]</p>
      
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">1. Introduzione</h2>
        <p className="text-gray-700">Benvenuto/a nell'app [Nome App] ("noi", "ci" o "nostro"). Utilizziamo i cookie e tecnologie simili per migliorare la tua esperienza sull'applicazione e per garantire che essa funzioni in modo efficiente. Questa politica sui cookie spiega cosa sono i cookie, come li utilizziamo e quali sono le tue opzioni riguardo al loro uso.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">2. Cosa sono i cookie?</h2>
        <p className="text-gray-700">I cookie sono piccoli file di testo che vengono salvati sul tuo dispositivo (computer, smartphone, tablet) quando visiti un sito web o utilizzi un'applicazione. Questi file consentono al sito web o all'applicazione di ricordare le tue azioni e preferenze (come login, lingua, dimensione del carattere e altre preferenze di visualizzazione) per un certo periodo di tempo.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">3. Tipi di cookie che utilizziamo</h2>
        <ul className="list-disc list-inside text-gray-700">
          <li><strong>Cookie strettamente necessari:</strong> Questi cookie sono essenziali per il funzionamento dell'app e non possono essere disattivati nei nostri sistemi. Sono solitamente impostati in risposta ad azioni effettuate da te, come la richiesta di servizi, l'impostazione delle preferenze sulla privacy, l'accesso o la compilazione di moduli.</li>
          <li><strong>Cookie di performance:</strong> Questi cookie ci permettono di conteggiare le visite e le fonti di traffico, così possiamo misurare e migliorare le prestazioni della nostra app. Ci aiutano a sapere quali sono le pagine più e meno popolari e a vedere come i visitatori si muovono all'interno dell'app.</li>
          <li><strong>Cookie di funzionalità:</strong> Questi cookie consentono all'app di fornire funzionalità avanzate e personalizzazioni. Possono essere impostati da noi o da fornitori terzi i cui servizi abbiamo aggiunto alle nostre pagine.</li>
          <li><strong>Cookie di targeting:</strong> Questi cookie possono essere impostati attraverso la nostra app dai nostri partner pubblicitari. Possono essere utilizzati da queste società per profilare i tuoi interessi e mostrarti annunci pertinenti su altri siti.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">4. Cookie di terze parti</h2>
        <p className="text-gray-700">Oltre ai nostri cookie, possiamo anche utilizzare vari cookie di terze parti per segnalare le statistiche di utilizzo dell'app e per altre finalità.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">5. Gestione dei cookie</h2>
        <p className="text-gray-700">Puoi controllare e/o eliminare i cookie come preferisci - per dettagli, visita <a href="https://aboutcookies.org" className="text-blue-500 underline">aboutcookies.org</a>. Puoi eliminare tutti i cookie che sono già presenti sul tuo dispositivo e impostare la maggior parte dei browser per impedire che vengano inseriti. Tuttavia, se fai ciò, potrebbe essere necessario regolare manualmente alcune preferenze ogni volta che visiti un sito e alcuni servizi e funzionalità potrebbero non funzionare.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">6. Consenso</h2>
        <p className="text-gray-700">Utilizzando la nostra app, acconsenti all'uso dei cookie in conformità con questa politica sui cookie. Se non acconsenti all'uso dei cookie, ti preghiamo di disabilitarli seguendo le istruzioni di cui sopra in modo che i cookie dal nostro sito non possano essere collocati sul tuo dispositivo.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">7. Modifiche alla Politica sui Cookie</h2>
        <p className="text-gray-700">Ci riserviamo il diritto di modificare questa politica sui cookie in qualsiasi momento. Eventuali modifiche saranno pubblicate su questa pagina. Si consiglia di controllare periodicamente questa pagina per essere informati su eventuali cambiamenti.</p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-2">8. Contatti</h2>
        <p className="text-gray-700">Se hai domande o dubbi riguardanti questa politica sui cookie, puoi contattarci all'indirizzo [indirizzo email] o [numero di telefono].</p>
      </section>
    </div>
  );
}

export default CookiePolicy;
