import React from 'react';
import { FaLock, FaUser, FaDollarSign } from 'react-icons/fa';

const ValueProposition = () => {
  return (
    <div className="container mx-auto p-4 lg:mt-28">
      <div className="grid grid-cols-2 gap-5">
        <div className="h-32 flex items-center justify-center text-3xl font-bold text-whiteT p-6">
          Perché scegliere Lexi?
        </div>
        <div className="bg-glass rounded-lg h-32 relative flex items-center justify-center  text-whiteT shadow-lg text-white">
          <FaLock className="absolute top-2 right-2 text-2xl" />
          <h3 className="text-2xl font-semibold mb-2 p-4">Sicurezza avanzata</h3>
        </div>
        <div className="bg-glass rounded-lg h-32 relative flex items-center justify-center  text-whiteT shadow-lg text-white">
          <FaUser className="absolute top-2 right-2 text-2xl" />
          <h3 className="text-2xl font-semibold mb-2 p-4">Usabilità intuitiva</h3>
        </div>
        <div className="bg-glass rounded-lg h-32 relative flex items-center justify-center  text-whiteT shadow-lg text-white">
          <FaDollarSign className="absolute top-2 right-2 text-2xl" />
          <h3 className="text-2xl font-semibold mb-2 p-4">Tariffe competitive</h3>
        </div>
      </div>
    </div>
  );
}

export default ValueProposition;
