import React, { useState } from 'react';
import { FiChevronDown } from 'react-icons/fi'; // Importa solo FiChevronDown
import './FAQ.css'; // Assicurati di importare il CSS

const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const faqs = [
        { question: 'Come posso connettere il mio portafoglio DeFi?', answer: 'Per connettere il tuo portafoglio DeFi, vai alla sezione "Impostazioni" dell\'app e seleziona "Connetti Portafoglio". Segui le istruzioni per collegare il tuo wallet.' },
        { question: 'Come posso creare una carta di credito?', answer: 'Per creare una carta di credito, accedi alla sezione "Carte" e seleziona "Crea Nuova Carta". Compila i dettagli richiesti e conferma la creazione della carta.' },
        { question: 'Come posso spendere le mie criptovalute?', answer: 'Per spendere le tue criptovalute, vai alla sezione "Spese" e scegli "Usa Cripto". Inserisci l\'importo e conferma la transazione.' },
        { question: 'Posso gestire più portafogli?', answer: 'Sì, puoi gestire più portafogli all\'interno dell\'app. Vai alla sezione "Portafogli" e aggiungi i tuoi wallet per una gestione centralizzata.' },
        { question: 'Come monitoro le mie spese?', answer: 'Puoi monitorare le tue spese nella sezione "Transazioni". Qui troverai un riepilogo delle tue spese recenti e un grafico dettagliato.' },
    ];

    return (
        <div className="w-full max-w-2xl mx-auto p-6">
            <h2 className="text-2xl font-bold mb-4">Domande Frequenti</h2>
            <div className="space-y-4">
                {faqs.map((faq, index) => (
                    <div key={index} className="border rounded-lg">
                        <button
                            onClick={() => handleToggle(index)}
                            className="w-full px-4 py-2 text-left bg-glass focus:outline-none flex items-center justify-between"
                        >
                            <span className="font-semibold">{faq.question}</span>
                            <span
                                className={`text-gray-100 transition-transform duration-300 ${openIndex === index ? 'rotate-180' : 'rotate-0'}`}
                            >
                                <FiChevronDown />
                            </span>
                        </button>
                        <div
                            className={`accordion-content transition-all duration-300 ${openIndex === index ? 'accordion-content-open' : 'accordion-content-closed'}`}
                        >
                            <div className="px-4 py-2 bg-glass">
                                <p>{faq.answer}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;
