import React from 'react';

const Footer = () => {
    return (
        <footer className="w-full p-8">
        <h1 className="text-2xl mb-2 text-center">Pay with Lexi</h1>
        <hr className="py-3 border-blue-gray-50" />
        <div className="flex flex-wrap items-center justify-center gap-y-6 gap-x-12 text-center md:justify-between">
          <ul className="flex flex-wrap justify-center items-center gap-y-2 gap-x-8 w-full">
            <li>
              <a href="/cookie-policy" className="text-blue-gray-700 font-normal transition-colors hover:text-blue-500 focus:text-blue-500">
                Privacy 
              </a>
            </li>
            <li>
              <a href="#" className="text-blue-gray-700 font-normal transition-colors hover:text-blue-500 focus:text-blue-500">
                Terms
              </a>
            </li>
            <li>
              <a href="/contattaci" className="text-blue-gray-700 font-normal transition-colors hover:text-blue-500 focus:text-blue-500">
                Contattaci
              </a>
            </li>
          </ul>
        </div>
        <hr className="my-8 border-blue-gray-50" />
        <p className="text-blue-gray-700 text-center font-normal -mt-3 -mb-3">
          &copy; 2024 Lexi
        </p>
      </footer>
      
    );
};

export default Footer;
