import React from 'react';
import { motion } from 'framer-motion';
import { FaWallet, FaBitcoin, FaCreditCard, FaGlobe } from 'react-icons/fa'; // Importa icone da react-icons
import walletImg from './img/walletImg.png';
import cardImg from './img/cardImg.png';
import coinImg from './img/coinImg.png';
import globeImg from './img/globeImg.png';


const iconAnimation = {
    initial: { y: 0 },
    animate: {
        y: [0, -10, 0],
        transition: { duration: 2, repeat: Infinity, repeatType: 'reverse' }
    }
};

const services = [
    {
        id: 1,
        icon: <img src={walletImg} alt="Conti Correnti" className="w-auto h-24" />, // Usa l'immagine per il wallet
        title: 'Conti Correnti',
        description: 'Categorizzazione delle spese, budget mensili, notifiche in tempo reale.',
    },
    {
        id: 2,
        icon: <img src={coinImg} alt="Conti Correnti" className="w-auto h-24" />, // Usa l'immagine per il wallet
        title: 'Criptovalute',
        description: 'Acquisto e vendita istantanea, supporto per staking.',
    },
    {
        id: 3,
        icon: <img src={cardImg} alt="Conti Correnti" className="w-auto h-24" />, // Usa l'immagine per il wallet
        title: 'Carte di Debito',
        description: 'Conversione automatica, cashback in criptovalute.',
    },
    {
        id: 4,
        icon: <img src={globeImg} alt="Conti Correnti" className="w-auto h-24" />, // Usa l'immagine per il wallet
        title: 'Pagamenti Internazionali',
        description: 'Trasferimenti a basso costo, peer-to-peer senza commissioni.',
    }
];

const ServicesOverview = () => {
    return (
        <div id="services" className="services-overview mt-28 mb-10 px-2 lg:-mt-52">
            <div className="container mx-auto text-center">
                <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
                    <h2 className="text-4xl font-bold mb-8">Gestione completa di valute fiat e criptovalute</h2>
                    <p className="text-xl mb-8">Breve descrizione delle principali funzionalità offerte (conti correnti, criptovalute, carte di debito, pagamenti internazionali).</p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
                        {services.map((service, index) => (
                            <div 
                                key={service.id} 
                                className={`p-6 bg-glass rounded-lg shadow-md backdrop-blur-xs flex items-center ${index % 2 === 1 ? 'flex-row-reverse' : 'flex-row'}`}
                            >
                                <motion.div {...iconAnimation} className="mr-4">
                                    {service.icon}
                                </motion.div>
                                <div className={`flex-1 ${index % 2 === 1 ? 'text-left' : 'text-right'}`}>
                                    <h3 className="text-2xl font-semibold mb-2">{service.title}</h3>
                                    <p>{service.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default ServicesOverview;
