import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import './Navbar.css'; // Assicurati di importare il foglio di stile

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setIsScrolled(true);
    } else if (window.scrollY <= 50) {
      setIsScrolled(false);
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 50) { // Modifica il valore in base alle tue esigenze
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`fixed w-full z-50 bg-glass backdrop-blur-xs text-white shadow-lg ${isScrolled ? 'scrolled' : ''}`}>
      <div className="container mx-auto px-6 py-4 flex justify-between items-center">
        <a href='/' className="text-2xl font-bold">Lexi</a>
        <div className="hidden md:flex space-x-4">

          <Link to="services" smooth={true} duration={500} className="cursor-pointer">Servizi</Link>
          <a href="/contattaci"  className="cursor-pointer">Contattaci</a>
        </div>
        <div className="md:hidden flex items-center">
          <label className="burger">
            <input type="checkbox" checked={isOpen} onChange={toggleMenu} />
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>
      </div>
      <div className={`dropdown-menu md:hidden bg-[##0d4bb2] rounded-b-xl text-white shadow-lg ${isOpen ? 'open' : ''}`}>
        <div className="px-6 py-4 space-y-4">
          <Link to="services" smooth={true} duration={500} className="block cursor-pointer" onClick={toggleMenu}>Servizi</Link>
          <a to="href"  className="block cursor-pointer" onClick={toggleMenu}>Contattaci</a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
