import React from 'react';
import { FiCheck, FiX } from 'react-icons/fi';

const ComparisonTable = () => {
    const benefits = [
        { text: 'Supporto per più criptovalute', lexi: true, competitors: true },
        { text: 'Conversione automatica in fiat', lexi: true, competitors: false },
        { text: 'Sicurezza avanzata con 2FA', lexi: true, competitors: true },
        { text: 'Cashback in criptovalute', lexi: true, competitors: false },
        { text: 'Nessuna commissione sulle transazioni', lexi: true, competitors: true },
        { text: 'Integrazione con wallet DeFi', lexi: true, competitors: false },
        { text: 'Gestione delle spese in tempo reale', lexi: true, competitors: false },
        { text: 'Supporto 24/7', lexi: true, competitors: true },
        { text: 'Accesso a carte virtuali e fisiche', lexi: true, competitors: false },
        { text: 'App mobile user-friendly', lexi: true, competitors: true },
        { text: 'Protezione contro frodi', lexi: true, competitors: false },
    ];

    return (
        <div className="p-10 px-24">
            <div className="overflow-x-auto shadow-md bg-glass rounded-lg">
                <table className="min-w-full">
                    <thead>
                        <tr>
                            <th className="py-3 px-6 bg-[#203b66e5] text-white">Vantaggi</th>
                            <th className="py-3 px-6 bg-[#203b66e5] text-white">LEXI</th>
                            <th className="py-3 px-6 bg-orange-500 text-white">COMPETITORS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {benefits.map((benefit, index) => (
                            <tr
                                key={index}
                                className={`text-center ${index === benefits.length - 1 ? 'border-b-0' : 'border-b'}`}
                            >
                                <td className="py-3 px-6 text-left">{benefit.text}</td>
                                <td className="py-3 px-6">
                                    {benefit.lexi ? (
                                        <div className="bg-gray-100 p-1 rounded-full inline-block">
                                            <FiCheck className="text-green-500 mx-auto" style={{ strokeWidth: 5 }} />
                                        </div>
                                    ) : (
                                        <div className="bg-gray-100 p-1 rounded-full inline-block">
                                            <FiX className="text-red-500 mx-auto" style={{ strokeWidth: 5 }} />
                                        </div>
                                    )}
                                </td>
                                <td className="py-3 px-6">
                                    {benefit.competitors ? (
                                        <div className="bg-gray-100 p-1 rounded-full inline-block">
                                            <FiCheck className="text-green-500 mx-auto" style={{ strokeWidth: 5 }} />
                                        </div>
                                    ) : (
                                        <div className="bg-gray-100 p-1 rounded-full inline-block">
                                            <FiX className="text-red-500 mx-auto" style={{ strokeWidth: 5 }} />
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ComparisonTable;
